.section-10 {
	padding-top: 10px;
	padding-bottom:10px;
}

.section-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.section-30 {
	padding-top: 30px;
	padding-bottom:30px;
}

.section-40 {
	padding-top: 40px;
	padding-bottom:40px;
}



.section-top-40 {
	padding-top: 40px;
}

.section-top-25 {
	padding-top: 25px;
}

.section-50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section-70 {
	padding-top: 70px;
	padding-bottom:70px;
}

.section-blue {
	background-color:$color-dark-blue;
	color: $color-white;
}

.section-light-blue {
	background-color:$color-blue;
	color: $color-white;
}

.section-grey {
	background-color:$color-back-grey;
	color: $color-dark-blue;
}

.section-white {
	background-color:$color-white;
}

.flex {
	display: flex;
}

.form-control {
	border-radius: 0;
}

.margin-carousel {
	margin-top: 1.5em;
}

.btn-green {
	padding: 10px 12px;
	color: $color-white;
	background-color: $color-green;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 700;
	border-radius:0;
	border:none;
	box-shadow: inset 0 0 0 0 lighten($color-green, 10%);
	transition: ease-out 0.4s;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;


	&:hover {
		color: $color-white;
		box-shadow: inset 800px 0 0 0 lighten($color-green, 10%);
	}

	@include xs-devices {
		font-size: 16px;
	}
}



.btn-orange {
	padding: 10px 12px;
	color: $color-white;
	background-color: $color-orange;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 700;
	border-radius:0;
	border:none;
	box-shadow: inset 0 0 0 0 darken($color-orange, 5%);
	transition: ease-out 0.4s;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;

	&:hover {
		color: $color-white;
		box-shadow: inset 1000px 0 0 0 darken($color-orange, 5%);
	}

	@include xs-devices {
		font-size: 16px;
	}
}


.btn-dark-blue {
	color: $color-white;
	background-color: $color-blue;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 700;
	border-radius:0;
	border:none;
	padding: 10px 21px;
	box-shadow: inset 0 0 0 0 $color-dark-blue;
	transition: ease-out 0.8s;
	-webkit-transition: ease-out 0.8s;
	-moz-transition: ease-out 0.8s;

	&:hover {
		color: $color-white;
		box-shadow: inset 800px 0 0 0 $color-dark-blue;
	}

}


.btn-tel {
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.bold-text {
	font-weight: 700;
}

.row-vertical {
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-flex;
	display: flex;

	@include xs-devices {
		display: inherit;
	}
	@include sm-devices {
		display: inherit;
	}
}


.row-stretch {
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	display: -webkit-flex;
	display: flex;

	@include xs-devices {
		display: inherit;
	}
	@include sm-devices {
		display: inherit;
	}
}

.item-stretch {
	align-self: stretch;
}


.row-wrap {
	display: flex;
	flex-wrap: wrap;

	@include xs-devices {
		display: inherit;
	}
	@include sm-devices {
		display: inherit;
	}

}

.row-vertical-engagements {
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-flex;
	display: flex;

	@include xs-devices {
		display: inherit;
	}
}

.menu-vertical {
	@include sup-xs-device {
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-flex;
		display: flex;
		justify-content: space-between;
	}
}


.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	background: $color-blue;
}

.fa-margin-left {
	margin-left: 5px;
}

.fa-margin-right {
	margin-right: 5px;
}

.result-txt {
	font-size: 28px;
	color: $color-white;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 20px;
}

.result-img-left {
	border-right: 2px solid transparent;
}

.result-img-right {
	border-left: 2px solid transparent;
}

.no-radio-btn > input[type="radio"] {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.text-white {
	color: $color-white;
}

.mgtop0 {
	margin-top: 0;
}

.no-padding-row {
	padding-left: 0;
	padding-right: 0;
}

.fix-padding-h2 {
	padding-bottom: 30px;
}

.height100 {
	height: 100%;
}


.backgroundEssai {
	margin-top: -187px;

	@include xs-devices {
		margin-top: 0;
	}
}

.padding-produits {
	padding-bottom: 188px;
	background-color: $color-blue;

	@include xs-devices {
		padding-bottom: 0;
	}
}

.padding-produits2 {
	padding-top: 162px;
	background: $color-blue;
	background: -webkit-linear-gradient(left,  $color-blue, darken($color-blue, 8%)); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, $color-blue, darken($color-blue, 8%)); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, $color-blue, darken($color-blue, 8%)); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, $color-blue, darken($color-blue, 8%)); /* Standard syntax (must be last) */

	@include xs-devices {
		padding-top: 0;
	}
}

.section-light-blue-gradient {
	background: $color-blue;
	background: -webkit-linear-gradient(left,  $color-blue, darken($color-blue, 8%)); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, $color-blue, darken($color-blue, 8%)); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, $color-blue, darken($color-blue, 8%)); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, $color-blue, darken($color-blue, 8%)); /* Standard syntax (must be last) */
	color: $color-white;
}

.section-bicolor {
	background: $color-blue;
	background: -webkit-linear-gradient(left,  $color-blue 75%, $color-back-grey 25%); /* For Safari 5.1 to 6.0 */
	background: linear-gradient(to right,  $color-blue 0%, $color-blue 75%, $color-back-grey 50%, $color-back-grey 100%); /* Standard syntax (must be last) */
	color: $color-white;
}

.section-top-40-mobile {
	@include xs-devices {
		padding-top: 40px;
	}
}

.img-produit {
	box-shadow: 0px 10px 43px 0px rgba(0,0,0,0.5);
	-webkit-box-shadow: 0px 10px 43px 0px rgba(0,0,0,0.5);
	margin-bottom: 65px;

	&.variante-img-produit {
		margin-top: 30px;
		margin-bottom: 0;
	}
}

.section-form {
	background-color: $color-white;
	border-radius: 5px;
	box-shadow: 2px 8px 15px #dfdede;
	-webkit-box-shadow: 2px 8px 15px #dfdede;


}

.section-form-no-shadow {
	box-shadow: none;
	-webkit-box-shadow: none;
}

.section-form-title {
	margin-left: -15px;
	margin-right: -15px;
	padding-top: 20px;
	padding-bottom: 20px;
	background: $color-blue;
	border-radius: 5px 5px 0 0;


	& > h2 {
		font-size: 25px;
		line-height: 33px;
		font-weight: 600;
		color: $color-white;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.section-form-title-2 {
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 5px 5px 0 0;


	& > h2 {
		font-size: 25px;
		line-height: 33px;
		font-weight: 400;
		color: $color-grey;
		margin-top: 0;
		margin-bottom: 0;

		& > .bold-form-title-h2 {
			font-weight: 600;
		}

	}
}



.padding-top0 {
	padding-top: 0;
	@include xs-devices {
		padding-top: 40px;
	}
}

#owl-carousel-produit .item-carousel img {
	height: 440px;
	display: block;
	width: 100%;

	@include md-devices {
		width: 100%;
		height: auto;
	}

	@include sm-devices {
		width: 100%;
		height: auto;
	}

	@include xs-devices {
		width: 100%;
		height: auto;
	}
}

.pos-relative {
	position: relative;
}

.mgtop25 {
	margin-top: 25px;
}

.intro-form input {
	width: 100%;
	padding: 0 15px;
	height: 50px;
	border-radius: 0;
	font-size: 15px;
	color: #000;
	border: 2px solid #ddd;
	transition: .4s;
	outline: 0;
	box-shadow: none;
}

.intro-form input:focus {
	border: 2px solid $color-blue;
}

.intro-form textarea {
	width: 100%;
	padding: 10px 15px;
	border-radius: 0;
	font-size: 15px;
	color: #000;
	border: 2px solid #ddd;
	transition: .4s;
	outline: 0;
	box-shadow: none;
}

.btn-form-blue {
	border: none;
	border-radius: 0;
	padding: 13px 20px;
	min-width: 180px;
	background: $color-blue;
	color: $color-white;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	transition: 0.8s;

	&:hover {
		background: darken($color-blue, 10%);
		text-decoration: none;
		color: $color-white;
	}

}

.btn-form-orange {
	border: none;
	border-radius: 0;
	padding: 13px 20px;
	min-width: 180px;
	background: $color-orange;
	color: $color-white;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 15px;
	font-weight: 700;
	text-align: center;
	transition: 0.8s;

	&:hover {
		background: darken($color-orange, 6%);
		text-decoration: none;
		color: $color-white;
	}

}

.msg-form-submit {
	font-size:15px;
}

ul.liste-PMR
{
    list-style-position:inside;
    list-style-image: url('../images/item.gif');
}
ul.liste-PMR li
{
	color: #2a9acf;
	font-size: 1em;
	line-height: 2em;
	font-weight: bold;
	text-transform: uppercase;
	
}


/*************** EN PLUS ************/
.section-image {
	background: url("/assets/images/lp_background.jpg") #ffffff no-repeat right top;  }

.style_lp
{
	font-size: 36px; font-weight: bold; letter-spacing: -2px; color:#ffffff; line-height: 30px;text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}
.style_lp_2
{
	font-size: 30px; letter-spacing: -2px; color:#ffffff; line-height: 28px;text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}
.pad-bottom-20
{
	padding-bottom: 20px;
}
.pad-bottom-130
{
	padding-bottom: 130px;
}

ul.list-titre li
{
	font-size: 25px;
	color: #ffffff;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 20px;
}
ul.list-titre {
	margin-top: 20px;
	list-style-image: url('/assets/images/check_green.png');
	list-style-position:inside;
	text-shadow: 2px 2px 4px rgba(0,0,0,0.4);
}

@media (max-width: 1024px) {
	.pad-bottom-130
	{
		padding-bottom: 40px;
	}
	.section-top-40
	{
		padding-top: 10px;
	}
	.style_lp
	{
		font-size: 28px; font-weight: bold; letter-spacing: -2px; color:#ffffff; line-height: 24px;
	}
}

@media (max-width: 768px) {
	.pad-bottom-130
	{
		padding-bottom: 20px;
	}
	.style_lp
	{
		font-size: 28px;
	}
	.style_lp_2
	{
		font-size: 24px;
	}
	ul.list-titre li
	{
		font-size: 18px;
		margin-bottom: 10px;
	}
	ul.list-titre li:last-Child
	{
		margin-bottom: 30px;
	}
	ul.list-titre {
		margin-top: 30px;
	}
	.pad-bottom-130
	{
		padding-top: 0px;
	}

}

@media (max-width: 800px) {
	.screen-none li:nth-child(even)
	{
		display: none;
	}
}
@media (max-width: 600px) {
	.screen-none li:nth-child(even)
	{
		display: list-item;
	}
	.screen-ad li
	{
		display: none;
	}
	.section-top-40
	{
		padding-top: 0;
	}
}
@media (min-width: 800px) {

	.screen-ad li
	{
		display: none;
	}
}


/****************************************************/