.card-body {
	/*@include margin-mobile-bottom(40px);*/
	@include xs-devices {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border: 1px solid $color-blue;
	}
	@include sm-devices {
		margin-bottom: 40px;
	}
	transition: ease-out 0.4s;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
	border: 1px solid transparent;
	&:hover {
		background: $color-white;
		border: 1px solid $color-blue;
	}
}

.card-link {
	&:hover {
		text-decoration: none;
	}
}

.card-content {
	padding-top: 45px;
}

.card-desc {
	padding: 8px;
	text-align: justify;
}

.card-button {
	margin-top: 25px;
	padding-bottom: 8px;
}


.card-body-article {
	/*@include margin-mobile-bottom(40px);*/
	@include xs-devices {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border: 1px solid $color-blue;
	}
	@include sm-devices {
		margin-bottom: 40px;
	}
	transition: ease-out 0.4s;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
	border: 1px solid $color-article-border;
	&:hover {
		background: $color-white;
		border: 1px solid $color-blue;
	}
}

.card-link-article {
	&:hover {
		text-decoration: none;
	}
}

.card-content-article {
	padding: 10px 35px;
}

.card-desc-article > p {
	font-size: 16px;
	line-height: 23px;
	margin-top: 20px;
}

.card-button-article {
	margin-top: 25px;
	padding-bottom: 8px;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 23px;
	text-decoration: none;
	cursor: pointer;
	color: $color-dark-blue;
	float: left;

	&:hover {
		color: $color-blue;
		text-decoration: none;
	}
}



.bloc-infos {
	text-align: center;

	@include margin-mobile-top-bottom(15px);

}

.bloc-atout {
	text-align: center;

	@include margin-mobile-bottom(40px);
}

.bloc-conseil {
	padding-bottom: 20px;
}

.bloc-conseil-desc > p {
	text-align: justify;
}

.bloc-conseil-button {
	margin-top: 25px;
}

.bloc-medias {
	padding-top:60px;
}

.bloc-media-item {
	@include margin-mobile-top-bottom(10px);
}

.bloc-solution-desc {
	text-align: justify;
}

.bloc-engagement-content > p {
	text-align: justify;
}

.bloc-mentions-legales > p {
	text-align: justify;
}

.bloc-contact {
	padding-top: 50px;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-flex;
	display: flex;
	@include xs-devices {
		display: inherit;
	}
	@include sm-devices {
		display: inherit;
	}
}

.bloc-contact-right-section {
	@include margin-mobile-bottom(30px);
}


.bloc-contact-tel > p {
	font-size: 28px;
	font-weight: 700;
	color: $color-white;

	&.accroche {
		font-size: 20px;
	}
}

.bloc-contact-date > p {
	font-size: 16px;
	/*color: $color-blue;*/
	color: $color-white;
}

.bloc-video {
	margin-top: 50px;
}

.bloc-video-quote {
	@include margin-mobile-top(40px);
}

.bloc-video-quote-color {
	color: $color-black;
	font-size: 16px;
	text-align: justify;

}

.bloc-contact-niv1 {
	padding-left: 20px;
	padding-right: 20px;
	align-self: center;
}

.bloc-contact-niv1-img {
	@include xs-devices {
		width: 100%;
	}
	@include sm-devices {
		width: 100%;
	}
}

.bloc-engagement {
	@include margin-mobile-top-bottom(10px);
	@include xs-devices {
		text-align: center;
	}
}

.bloc-engagement-content {
	@include margin-mobile-top(20px);
}

.bloc-article-title {
	font-size: 20px;

	display: inline;
	font-weight: 400;
	color: $color-blue;

	 &.article-category {
		 margin-top: 5px;
	}

	&:hover {
		text-decoration: underline;
	}
}

.bloc-article-first {
	@include xs-devices {
		margin-bottom: 18px;
	}

	@include sm-devices {
		margin-bottom: 18px;
	}
}

.bloc-article-home-content {
	padding-top: 10px;
	@include sm-devices {
		padding-top: 0;
	}

	@include  xs-devices {
		padding-top: 0;
	}
}

.bloc-article-first-img {

	width: 100%;
}

.bloc-article-second {
	margin-bottom: 18px;
}


.footer {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: $color-white;
}

.footer-link {
	display: inline-block;
	padding-left: 25px;
	padding-right: 25px;
	font-size: 16px;

	@include xs-devices {
		display: block;
		margin-bottom: 10px;
	}
}

.footer-link:not(:last-child) {    
    border-right: 2px solid $color-dark-grey;

	@include xs-devices {
		border: none;
	}
}

.form-radio-input input{
	margin:0;padding:0;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}

.form-radio-img {
	cursor:pointer;
	-webkit-transition: all 100ms ease-in;
	-moz-transition: all 100ms ease-in;
	transition: all 100ms ease-in;
	-webkit-filter: brightness(1.5) grayscale(1) opacity(1);
	-moz-filter: brightness(1.5) grayscale(1) opacity(1);
	filter: brightness(1.5) grayscale(1) opacity(1);

	&:hover {
		-webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
		-moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
		filter: brightness(1.2) grayscale(.5) opacity(.9);
/*
		@include xs-devices {
			-webkit-filter: none;
			-moz-filter: none;
			filter: none;
		}
	*/
	}
}

.form-radio-input input:active +.form-radio-img{opacity: .9;}
.form-radio-input input:checked +.form-radio-img{
	-webkit-filter: none;
	-moz-filter: none;
	filter: none;
}

.form-radio-img-span {
	display: block;
	margin-top: 7px;
}

.form-radio-select-txt {
	font-size: 18px;
	text-align: center;
}

.full-article-content > p {
	text-align: justify;
}
.full-article-content > h2, .full-article-content > h3, .full-article-content > h4, .full-article-content > h5, .full-article-content > h6  {
	margin-top: 20px;
	margin-bottom: 20px;
}

.full-article-title {
	font-size: 36px;
	letter-spacing: -1px;
}

.full-article-img {
	margin-top: 30px;
	margin-bottom: 30px;
}