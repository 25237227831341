/* .navbar-collapse {
    float: left;
    width: 100%;
    clear: both;
}

@media (min-width: 768px) {
    /* .navbar-right {
        margin-top: -50px;
    } 
} */
.header-menu {
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
	display: block;
	margin-bottom: 10px;
	margin-top: 10px;
}

.logo-menu {
	text-align: center;
	font-size: 28px;
	color: $color-dark-blue;
	line-height: 1;
}

.logo-menu {
	&:hover {
		text-decoration: none;
	}
}

.logo-menu-blue {
	color: $color-blue;
}

.logo-menu-baseline {
	font-size: 16px;
	color: $color-grey;
}

@media (min-width: 768px) { 
    .twoRow .navbar-collapse {
        clear: left;
    }
}

.navbar {
	margin-bottom:0;
	border: none;
	background: $color-white;

	@include xs-devices {
		border-bottom: 3px solid $color-blue;
	}
}

.no-padding-nav {
	padding-left:0;
	padding-right: 0;
}

.navbar-dark-blue {
	background-color: $color-dark-blue;
	color: $color-white;
}

.navbar-dark-blue > ul > li {

	&:hover {
		/*background: none;*/
		background: $color-blue;
	}

}

.navbar-dark-blue > ul > li > a {
	color: $color-white;
	font-size: 20px;
	font-weight: 600;
	
	&:hover {
		/*background: none;*/
		background: $color-blue;
		text-decoration: none;
		color: $color-white;

		& > span {
			border-bottom: 2px solid $color-white;
		}

	}
}

/*.navbar-dark-blue > ul > li {
	display: table-cell;
	float: none;
	text-align: center;
}*/

.fa-navbar {
	font-size: 48px;
	margin-right: 14px;
	color: $color-dark-blue;

	&:hover {
		text-decoration: none;
		color: $color-dark-blue;
	}
}

.navbar-link-tel {
	&:hover {
		text-decoration: none;
	}
}

.inline-block {
	display: inline-block;
	vertical-align: middle;
}
.navbar-inline-block {
	padding-top:0;
	padding-bottom: 0;
	vertical-align: middle;
}

.navbar-tel {
	font-size: 26px;
	font-weight: 700;
	color: $color-dark-blue;
	line-height: 1;
	margin-bottom: 0;

	@include xs-devices {
		font-size: 21px;
	}

	@include xxs-devices {
		font-size: 18px;
	}
}

.navbar-opening {
	font-size: 16px;
	line-height: 1;
	color: $color-grey;
	margin-bottom: 0;
}

.navbar-header {
	width: 100%;
}


.navbar-nav {
	@include sup-xs-device {
		display:table;
		width:100%;
		margin: 0;
	}

	@include xs-devices {
		margin: 0;
		padding-top: 10px;
		padding-bottom: 10px;
	}

}

.navbar-nav > li {

	@include sup-xs-device {
		float:none;
		display:table-cell;
		text-align:center;
	}

}

.navbar-toggle .icon-bar {
	background: $color-blue;
	height: 4px;
	width: 26px;
}

.nav>li {
	@include xs-devices {
		padding-left: 20px;
	}
}

.lg-padding-menu {
	@include lg-devices {
		padding-left: 10%;
		padding-right: 10%;
	}
}

/*.navbar-toggle:before {
	content: "MENU";
	left: -60px;
	top: 37%;
	position: absolute;
	width: 50px;
	font-size: 19px;
	color: #189bcd;
	font-weight: 700;
}*/



.mobile-navbar {
	@include xs-devices {
		max-width: 80%;
	}

	@include xxs-devices {
		max-width: 100%;
	}
}

.flex-toggle-menu {
	align-self: center;
	color: $color-blue;
	font-weight: 700;
	font-size: 20px;

	@include xxs-devices {
		font-size: 16px;
	}
}

.menu-img {
	width: 25px;
	height: 25px;
	margin-bottom: 5px;

	@include xs-devices {
		display: inline;
		margin-bottom: 0;
		margin-right: 10px;
	}
}

.active-menu {
	/*background: $color-blue;*/
	/*text-decoration: underline;*/
}

.active-menu > a > span {
	border-bottom: 2px solid $color-white;
}