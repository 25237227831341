$xxs-width: 365px;
$xs-width: 767px;
$sm-width: 768px;
$md-width: 992px;
$lg-width: 1200px;

$color-white: #ffffff;
$color-black: #000000;
$color-dark-grey: #434343;
$color-grey: #424242;
$color-dark-blue: #040f3a;
$color-blue-hover: #179ac6;
$color-blue: #329bcd;
$color-light-blue: #00b3d1;
$color-green: #38731d;
$color-back-grey: #f3f3f3;
$color-orange: #ff9900;
$color-article-border: #bdbdbd;