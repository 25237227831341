* {    
  box-sizing: border-box;   
  margin: 0;    
  padding: 0;  
}

body {
	font-family: 'Open Sans',"Helvetica Neue",Helvetica,Arial, sans-serif;
}

h1 {
	font-size: 29px;
}

h2 {
	font-size: 29px;
}

.custom-h2-blue {
	font-weight: 700;
	padding-bottom: 40px;

	&.form-h2 {
		font-size: 25px;
		line-height: 33px;
		font-weight: 600;
		margin-top: 0;
	}
}

.custom-h2-engagements {
	margin-top: 0;
	color: $color-blue;
	font-weight: 700;
}

.h2-ml {
	font-size: 23px;
}

.card-h2 {
	font-size:21px;
	color: $color-black; 
	font-weight: 700;

}

.card-h2-article {
	font-size:21px;
	color: $color-black;
	font-weight: 700;

}

h3 {
	font-size:20px;
	color: $color-black; 
	font-weight: 700;
}

.custom-h3 {
	font-weight: 400;
}

p {
	font-size: 17px;
	color: $color-grey
}

.text-black {
	color: $color-black;
}

a {
	color: $color-blue;
}

blockquote {
	border-left: 5px solid $color-blue;
}

hr {
	border-top: 1px solid $color-blue;
}

textarea {
	resize: vertical;
}

#myModal-result {



.alert {
		margin-bottom: 0;
		text-align: center;
	}
}

#myModal-result {
	text-align: center;
	padding: 0!important;
}

#myModal-result:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -4px;
}

#myModal-result .modal-dialog {
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}
